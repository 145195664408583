@import './variables.scss';

*{@include default}


.headerSec{
    position: relative;
    padding: 20px;
    height: 90vh;
    background-color: rgba(1, 13, 3, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.headerSec::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(./img1.png);
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 100%;
    width: 100%;
}


.headerText{
    isolation: isolate;
    width: 60%;
    color: white;
}
.headerText h2{
    font-size: 60px;
    color: white;
    text-align: center;
    line-height: 60px;
}

.headerText p{
    text-align: center;
    font-size: 20px;
    padding: 30px 0;
}

.headerText button{
    padding: 15px 30px;
    background-color: #f8a114;
    color: white;
    display: flex;
    margin: auto;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 15px;
}


@media only screen and (min-width: $small-One) and (max-width: $medium-Two) {

    .headerSec{
        height: 70vh;
        width: 100vw;
    }
    .headerText{
        isolation: isolate;
        width: 100%;
        color: white;
        margin-top: 70px;
    }


    .headerText h2{
        font-size: 30px;
        color: white;
        text-align: left;
        line-height: 35px;
    }
    
    .headerText p{
        text-align: left;
        font-size: 15px;
        padding: 30px 0;
    }

    .headerText button{
        padding: 12px 30px;
        background-color: #f8a114;
        color: white;
        display: block;
        margin: 0;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        font-size: 13px;
    }
}



.soluSec{
    padding: 30px 10%;
}

.solutionDiv{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
}


.solutionDiv div{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 20px 30px;
    border-radius: 6px;
}

.solutionDiv p{
    font-size: 30px;
    color: #f8a114;
}

.blue p{
    color: rgb(5, 64, 5);
}

.solutionDiv h2{
    font-weight: 400;
}

.soluSec .our{
    font-size: 18px;
    padding-bottom: 20px;
    font-weight: 500;
}


@media only screen and (min-width: $small-One) and (max-width: $medium-Two) {
    .soluSec{
        padding: 30px 5%;
    }
    
    .solutionDiv{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 20px;
    }

    .solutionDiv div{
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding: 10px 20px;
        width: 100%;
    }

    .solutionDiv h2{
        font-weight: 400;
        font-size: 16px;
    }

    .solutionDiv p{
        font-size: 20px;
    }

    .solutionDiv span{
        font-size: 12px;
    }
    
}

.experineceDiv {
    background-color: $my-blue;
    padding: 30px 10%;
}



.experience{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
}

.experineceDiv h2{
    color: white;
    padding-bottom: 20px;
}

.experience div{
    background-color: white;
    padding: 30px;
    border-radius: 6px;
}


@media only screen and (min-width: $small-One) and (max-width: $medium-Two) {
    
    .experineceDiv h2{
        color: white;
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
    }
    
    .experience{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    } 

    .experience div{
        background-color: white;
        padding: 30px;
        font-size: 18px;
    }
}