
@import './variables.scss';

*{@include default}


// =================== NavBar =========================
.react-multiple-carousel__arrow {
    z-index: 0;
}
.navDivMain{
    background-color: $my-blue;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    position: fixed;
    z-index: 100;
    // background-color: transparent;
}

.maimLogo h2{
    color: white;
}

.logoSelf h2{
    color: white;
}


.navDiv{
    display: flex;
    padding: 15px 5%;
    isolation: isolate;
}

.maimLogo{
    display: none;
}

.firstNavDiv{
    display: flex;
    align-items: center;
    gap: 60px;
}


.firstNavDiv img{
    cursor: pointer;
}


// ========== NavUl =================
.navUl{
    display: flex;
    gap: 20px;
    width: 100%;
}

.navUl p{
    cursor: pointer;
    color: white;
}


.dropDown{
    display: flex;
    align-items: center;
}



// ================== Second Nav =================

.secondNavDiv{
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 20px;
}

.apply{
    text-decoration: underline;
    cursor: pointer;
}

.secondNavDiv button{
    @include button-style
}

.loginBtn{
    background-color: $my-white;
    border: 1px solid $my-blue;
    color: $my-blue;
    cursor: pointer;
}

.signupBtn{
    background-color: $my-white;
    border:none;
    color: $my-blue;
    cursor: pointer;
}

.signupBtn:hover{
    @include hover-blue
}

@media only screen and (min-width: $small-One) and (max-width: $medium-Two) {

    // ========== main Logo ===============
    .maimLogo{
        display: flex;
        padding: 15px 8%;
        align-items: center;
    }

    .maimLogo img{
        width: 100px;
    }


    // ============ First Div =============
    .firstNavDiv{
        display: block;
        
    }

    .logoSelf{
        display: none;
        
    }


    // =========== Nav UL =============

    .navDiv{
        background-color:$my-blue;
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 60px;
        bottom: 0;
        padding: 30px;
        z-index: 3;
        transition: transform 0.3s ease-in-out;
    }
      
    .navDiv.open {
        transform: translateY(100%);
    }


    .navUl{
        display: block;
        margin-bottom: 20px;
    }

    .navUl p{
        margin-bottom: 10px;
        font-size: 15px;
        width: fit-content;
    }

    .navIcons{
        margin-left: auto;
        font-size: 20px;
        color: white;
    }


    // ================ Second Div =================
    .secondNavDiv{
        display: block;
        margin-top: 10px;
    }

    .apply{
        width: fit-content;
    }
    
    .loginBtn, .signupBtn{
        display: block;
        margin-top: 15px;
        width: 50%;
    }
}


@media only screen and (min-width: 769px) and (max-width: 1023px) {
    .navDiv{
        display: flex;
        padding: 15px 2%;
        isolation: isolate;
    }
    .firstNavDiv{
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .firstNavDiv img{
        cursor: pointer;
        width: 60px;
    }

    .navUl p{
        cursor: pointer;
        font-size: 12px;
    }

    .apply{
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
    }

    .loginBtn{
        font-size: 12px;
        padding: 6px 15px !important;
    }
    
    .signupBtn{
        font-size: 12px;
        padding: 6px 15px !important;
    }
    
}


@media only screen and (min-width: $large-One) and (max-width: $large-Two) {
    .firstNavDiv{
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .navUl{
        display: flex;
        gap: 20px;
        width: 100%;
    }


    .firstNavDiv img{
        cursor: pointer;
        width: 60px;
    }
}